import { ApiError } from '@anm/api';
import {
  CurrentTemplateParams,
  SendTemplateLinkToEmailProps,
  TemplateItem,
  TemplateListParams,
  TemplateListResponse,
  UpdateTemplateParams
} from '@anm/api/modules/templates';
import { PageName } from '@anm/data/video-templates/pages';
import {
  createAction,
  createAsyncAction
} from '@anm/helpers/saga/typesafe-actions';

import { TemplatesFilterData } from '../filter';

import {
  FetchTemplateListByPageParams,
  TemplateListFilters,
  TemplateSliderParams
} from './types';

export const getTemplateList = createAction('wave.video/GET_TEMPLATE_LIST')();

export const fetchTemplateListAsync = createAsyncAction(
  'wave.video/FETCH_TEMPLATE_LIST_REQUEST',
  'wave.video/FETCH_TEMPLATE_LIST_SUCCESS',
  'wave.video/FETCH_TEMPLATE_LIST_ERROR'
)<TemplateListFilters, TemplateListResponse, ApiError>();

export const fetchTemplateListByPage = createAction(
  'wave.video/FETCH_TEMPLATE_LIST_BY_PAGE'
)<FetchTemplateListByPageParams>();

export const fetchTemplateListByPageAsync = createAsyncAction(
  'wave.video/FETCH_TEMPLATE_LIST_BY_PAGE_REQUEST',
  'wave.video/FETCH_TEMPLATE_LIST_BY_PAGE_SUCCESS',
  'wave.video/FETCH_TEMPLATE_LIST_BY_PAGE_ERROR'
)<TemplateListParams, TemplateListResponse, ApiError>();

export const fetchCurrentTemplateAsync = createAsyncAction(
  'wave.video/GET_CURRENT_TEMPLATE_REQUEST',
  'wave.video/GET_CURRENT_TEMPLATE_SUCCESS',
  'wave.video/GET_CURRENT_TEMPLATE_ERROR'
)<CurrentTemplateParams, TemplateItem, ApiError>();

export const updateTemplate = createAction('wave.video/UPDATE_TEMPLATE')<
  UpdateTemplateParams
>();

export const updateTemplateAsync = createAsyncAction(
  'wave.video/UPDATE_TEMPLATE_REQUEST',
  'wave.video/UPDATE_TEMPLATE_SUCCESS',
  'wave.video/UPDATE_TEMPLATE_ERROR'
)<void, TemplateItem, ApiError>();

export const cleanUpdateTemplate = createAction(
  'wave.video/CLEAN_UPDATE_TEMPLATE'
)();

export const fetchTemplateView = createAction('wave.video/FETCH_TEMPLATE_VIEW')<
  CurrentTemplateParams
>();
export const clearCurrentTemplate = createAction(
  'wave.video/CLEAR_CURRENT_TEMPLATE'
)();

export const fetchTemplateListByFilter = createAction(
  'wave.video/FETCH_TEMPLATE_LIST_BY_FILTER'
)<TemplatesFilterData>();

export const resetTemplateList = createAction(
  'wave.video/RESET_TEMPLATE_LIST'
)();

export const generateTemplates = createAction(
  'wave.video/GENERATE_TEMPLATES'
)();

export const addGeneratedTemplates = createAction(
  'wave.video/ADD_GENERATED_TEMPLATES'
)<TemplateListResponse>();

export const fetchTemplatesForSlider = createAction(
  'wave.video/FETCH_TEMPLATES_FOR_SLIDER'
)<TemplateSliderParams>();

export const fetchTemplatesForSliderAsync = createAsyncAction(
  'wave.video/FETCH_TEMPLATES_FOR_SLIDER_REQUEST',
  'wave.video/FETCH_TEMPLATES_FOR_SLIDER_SUCCESS',
  'wave.video/FETCH_TEMPLATES_FOR_SLIDER_ERROR'
)<TemplateSliderParams, TemplateListResponse, ApiError>();

export const setTemplatesPageName = createAction(
  'wave.video/SET_TEMPLATES_PAGE_NAME'
)<PageName>();

export const sendTemplateLinkToEmail = createAction(
  'wave.video/SEND_TEMPLATE_LINK_TO_EMAIL'
)<SendTemplateLinkToEmailProps>();

export const setCurrentPage = createAction(
  'wave.video/SET_CURRENT_TEMPLATES_PAGE'
)<number>();
