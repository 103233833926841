const combineStringArr = (
  first: string[],
  second: string[],
  combineFields: (firstField: string, secondField: string) => string
) =>
  first.reduce(
    (acc, firstVal: string) => [
      ...acc,
      ...second.map((secondVal: string) => combineFields(firstVal, secondVal))
    ],
    []
  );

export default combineStringArr;
