import { TemplateItem, TemplateSize } from '@anm/api/modules/templates';
import selectProps from '@anm/helpers/store/selectProps';
import pick from 'lodash/fp/pick';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

import { getPagesCount } from './helpers';
import { TemplateListState } from './types';

export const selectCurrentTemplate = ({ template }: ApplicationState) => template.currentTemplate.data;
export const selectCurrentTemplateError = ({ template }: ApplicationState) => template.currentTemplate.error;
export const selectCurrentTemplatePending = ({ template }: ApplicationState) => template.currentTemplate.isPending;

export const selectTemplateList = ({ template }: ApplicationState) => template.templateItems.list;

export const selectTemplateFiltersSearch = ({ template }: ApplicationState) => template.templateItems.filters.name;

export const selectTemplateListExcludeId = createSelector(
  selectTemplateList,
  selectProps<string | undefined>(),
  (templateList, excludedId) => (excludedId ? templateList.filter(t => t.templateId !== excludedId) : templateList)
);

export const selectCurrentTemplateMedia = ({ template }: ApplicationState) => template.currentTemplate.data?.meta.media;

export const selectTemplateListCount = ({ template }: ApplicationState) => template.templateItems.count;

export const selectTemplateBySlugOrId = createSelector(
  selectTemplateList,
  selectProps<string>(),
  (templateList, templateSlugOrId) =>
    templateList.find(({ slug, templateId }) => slug === templateSlugOrId || templateId === templateSlugOrId)
);

export const getTemplateById = (id: string) => ({ template }: ApplicationState) =>
  template.templateItems.list.find(({ templateId }) => templateId === id);

export const getTemplateItemsState = ({ template }: ApplicationState) => template.templateItems;

export const selectTemplateListFetchParams = createSelector(getTemplateItemsState, templateState =>
  pick<TemplateListState, keyof TemplateListState>(['limit', 'offset'])(templateState)
);

export const getTemplateItemsPending = ({ template }: ApplicationState) => template.templateItems.isPending;

export const selectPreviewFromList = (list: TemplateItem[]) => {
  const templateItem = list.find(({ meta }) => {
    const { media, defaultTarget } = meta;
    return media?.[defaultTarget];
  });

  if (templateItem) {
    const { media, defaultTarget } = templateItem.meta;
    return selectMediumSize(media[defaultTarget].thumb);
  }
};

const sortMedia = (thumbs: TemplateSize[]) => [...thumbs].sort((a, b) => a.size.height - b.size.height);

export const selectSmallSize = (thumbs: TemplateSize[]) => sortMedia(thumbs)[0];

export const selectDefaultTemplateMedia = (currentTemplate: TemplateItem) => {
  const { meta } = currentTemplate;
  const { media, defaultTarget } = meta;

  return media[defaultTarget];
};

export const selectMediumSize = (thumbs: TemplateSize[]) => {
  const arr = sortMedia(thumbs);
  const mediumVal = Math.ceil(arr.length / 2);
  return arr[mediumVal];
};

export const selectBigSize = (thumbs: TemplateSize[]) => {
  const arr = sortMedia(thumbs);
  return arr[arr.length - 1];
};

export const selectFilters = ({ filter }: ApplicationState) => filter.templates;

export const selectVideoFormats = ({ video }: ApplicationState) => video.formats.data;

export const selectGeneratedList = ({ template }: ApplicationState) => ({
  content: template.generatedItems.list,
  count: template.generatedItems.count
});

export const selectUpdateTemplateMeta = ({ template }: ApplicationState) => template.currentTemplate.updateTemplateMeta;

export const selectUpdateTemplateMetaPending = ({ template }: ApplicationState) =>
  template.currentTemplate.updateTemplateMeta.isPending;

export const selectTemplateCategoryItems = ({ template }: ApplicationState) => template.templatesItemsForSlider.data;

export const selectTemplatesByCategory = createSelector(
  selectTemplateCategoryItems,
  selectProps<string | undefined>(),
  (templates, key = 'all') => templates?.[key]
);

export const selectTemplatesByCategoryIsPending = createSelector(selectTemplateCategoryItems, templates => {
  if (!templates) return false;
  const keys = Object.keys(templates);

  const isPending = keys.reduce((acc, k) => acc || templates[k].isPending, false);

  return isPending;
});

export const selectTemplatePage = ({ template }: ApplicationState) => template.pageName;

export const selectTemplatesItemsLimit = ({ template }: ApplicationState) => template.templateItems.limit;

export const selectTemplatesCurrentPage = ({ template }: ApplicationState) => template.currentPage;

export const selectTemplatesPagesCount = createSelector(
  selectTemplatesItemsLimit,
  selectTemplateListCount,
  (limit, templatesCount) => getPagesCount(templatesCount, limit)
);

export const selectTemplateItemsStateMerge = ({ template }: ApplicationState) => template.templateItems.params.canMerge;

export const selectTemplateItemsAspects = ({ template }: ApplicationState) => template.templateItems.params.aspects;
