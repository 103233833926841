import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import {
  addUserProfile,
  reset,
  resetTemplateFilters,
  save,
  update,
  updateSavedFilters,
  updateSearchName
} from './actions';
import { replaceDashes } from './helpers';
import { FilterActions, FilterState, UserProfileInitData } from './types';

const initialFilterState: FilterState = {
  templates: {},
  searchName: '',
  savedFilters: {},
  initedByUserProfiles: []
};

const filterReducer: Reducer<FilterState, FilterActions> = (
  state = initialFilterState,
  action
) => {
  switch (action.type) {
    case getType(reset): {
      return {
        ...initialFilterState
      };
    }
    case getType(save): {
      const saved = action.payload;
      return {
        ...state,
        saved
      };
    }
    case getType(update): {
      const { templates } = action.payload;

      return {
        ...state,
        templates: {
          ...templates,
          category: replaceDashes(templates.category!)
        }
      };
    }
    case getType(addUserProfile): {
      const newUserProfile = action.payload;
      const {
        newUserProfiles,
        updatedUserProfile
      } = state.initedByUserProfiles.reduce(
        (acc, item) => {
          if (acc.updatedUserProfile.id === item.id) {
            acc.updatedUserProfile.filters = [...item.filters];
          } else {
            acc.newUserProfiles = [...acc.newUserProfiles, item];
          }
          return acc;
        },
        {
          newUserProfiles: [] as UserProfileInitData[],
          updatedUserProfile: newUserProfile
        }
      );

      return {
        ...state,
        initedByUserProfiles: [...newUserProfiles, updatedUserProfile]
      };
    }
    case getType(updateSearchName): {
      return {
        ...state,
        searchName: action.payload
      };
    }
    case getType(resetTemplateFilters): {
      return {
        ...state,
        templates: {}
      };
    }
    case getType(updateSavedFilters): {
      return {
        ...state,
        savedFilters: action.payload
      };
    }
    default:
      return state;
  }
};

export default filterReducer;
