import { createAction } from '@anm/helpers/saga/typesafe-actions';

import { FilterData, TemplatesFilterData, UserProfileInitData } from './types';

export const reset = createAction('wave.video/filters/RESET_FILTERS')();
export const save = createAction('wave.video/filters/SAVE_FILTERS')<
  FilterData
>();
export const update = createAction('wave.video/filters/UPDATE_FILTERS')<
  FilterData
>();
export const addUserProfile = createAction(
  'wave.video/filters/ADD_USER_PROFILE'
)<UserProfileInitData>();
export const updateSearchName = createAction(
  'wave.video/filters/UPDATE_SEARCH_NAME'
)<string>();
export const updateSavedFilters = createAction(
  'wave.video/filters/UPDATE_SAVED_FILTERS'
)<TemplatesFilterData>();

export const resetTemplateFilters = createAction(
  'wave.video/filters/RESET_TEMPLATES_FILTERS'
)<void>();
