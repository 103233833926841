import { combineReducers, Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { videoActions } from '.';
import { VideoActions, VideoFormatsState } from './types';

const initialVideoFormatsState: VideoFormatsState = {
  data: [],
  isError: false,
  isPending: false
};

const videoFormatsReducer: Reducer<
  typeof initialVideoFormatsState,
  VideoActions
> = (state = initialVideoFormatsState, action) => {
  switch (action.type) {
    case getType(videoActions.getVideoFormatsAsync.request): {
      return {
        ...state,
        error: null,
        isError: false,
        isPending: true
      };
    }
    case getType(videoActions.getVideoFormatsAsync.failure): {
      const { payload: error } = action;
      return {
        ...state,
        error,
        isError: true,
        isPending: false
      };
    }
    case getType(videoActions.getVideoFormatsAsync.success): {
      const { payload: data } = action;
      return {
        ...state,
        data,
        error: null,
        isError: false,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  formats: videoFormatsReducer
});
