import { ApplicationState } from '../../types';

export const selectTemplatesFilters = ({ filter }: ApplicationState) =>
  filter.templates;

export const isUserProfileInited = ({ filter }: ApplicationState) => (
  currentId: string
) => filter.initedByUserProfiles.some(({ id }) => id === currentId);

export const selectSearchName = ({ filter }: ApplicationState) =>
  filter.searchName;

export const selectSavedFilters = ({ filter }: ApplicationState) =>
  filter.savedFilters;
