import { ApiError } from '@anm/api';
import {
  TemplateItem,
  TemplateListResponse
} from '@anm/api/modules/templates/types';
import { VideoFormat } from '@anm/api/modules/video';
import { DEFAULT_FETCH_LIMIT } from '@anm/data/video-templates/defaults';
import removeDuplicates from '@anm/helpers/array/removeDuplicates';
import shuffle from 'lodash/shuffle';

import { getFormatList } from '../../../components/templates/VideoRatioList/helpers';

import { CategoryData } from './types';

export const getSelectedFormat = (
  aspectsInline: string,
  videoFormats: VideoFormat[]
) => {
  const aspects = aspectsInline.split(',');
  const aspectName = aspects[0];
  const selectedAspect = videoFormats.find(
    (item: VideoFormat) => item.name === aspectName
  );

  return selectedAspect!.format;
};

type GenerateProps = {
  mediaFormats: string[];
  template: TemplateItem;
  accumulator: TemplateItem[];
};

const generateTemplates = ({
  mediaFormats,
  template,
  accumulator
}: GenerateProps) => {
  mediaFormats.forEach(format => {
    const newTemplate = {
      ...template,
      slug: `${template.templateId}-${format}`,
      templateId: `${template.templateId}-${format}`,
      meta: {
        ...template.meta,
        defaultTarget: format
      }
    };

    accumulator.push(newTemplate);
  });

  return accumulator;
};

const createNewTemplates = (
  templates: TemplateItem[],
  videoFormats: VideoFormat[]
) =>
  templates.reduce((acc, template) => {
    const { media, defaultTarget } = template.meta;

    const formatList = getFormatList({
      media,
      videoFormats
    });

    const mediaFormatsWithoutDefault = formatList.filter(
      ({ name }) => name !== defaultTarget
    );

    if (mediaFormatsWithoutDefault.length === 0) return acc;

    const uniqueFormatsList = removeDuplicates(
      mediaFormatsWithoutDefault,
      'format'
    );
    const uniqueNamesList = removeDuplicates(uniqueFormatsList, 'displayName');

    const mediaFormats = uniqueNamesList.map(({ name }) => name);

    const newAccumulator = generateTemplates({
      mediaFormats,
      template,
      accumulator: acc
    });

    return newAccumulator;
  }, [] as TemplateItem[]);

export const generateAllFormatTemplates = (
  templates: TemplateItem[],
  videoFormats: VideoFormat[]
) => {
  const generatedTemplates = createNewTemplates(templates, videoFormats);

  const mixedList = shuffle(generatedTemplates);

  return { count: mixedList.length + templates.length, content: mixedList };
};

const hideAllPendings = (data: CategoryData) => {
  const keys = Object.keys(data);

  const newData = keys.reduce((acc, key) => {
    acc[key] = { ...data[key], isPending: false };

    return acc;
  }, {} as CategoryData);

  return newData;
};

export const getCategoryTemplate = (
  data: CategoryData,
  { content, count, ...restProps }: TemplateListResponse
) => {
  const newData = Object.keys(data).reduce((acc, category) => {
    if (!data[category].list?.length) {
      return {
        [category]: {
          ...restProps,
          list: content,
          params: data[category].params,
          isAllLoaded: content.length >= count
        }
      };
    }
    return acc;
  }, {});

  const result = {
    ...hideAllPendings(data),
    ...newData
  };

  return result;
};

export const getCategoryErrorState = (data: CategoryData, error: ApiError) => {
  const newData = Object.keys(data).reduce((acc, category) => {
    if (!data[category].list && !data[category].isError) {
      return {
        [category]: {
          error,
          isError: true
        }
      };
    }
    return acc;
  }, {});

  const result = {
    ...hideAllPendings(data),
    ...newData
  };

  return result;
};

export const getOffset = (pageNumber: number, limit = DEFAULT_FETCH_LIMIT) => {
  const prevPage = pageNumber - 1;
  const isFirstPage = pageNumber === 1;
  const offset = isFirstPage ? 0 : prevPage * limit;

  return offset;
};

export const getPagesCount = (templatesCount: number, limit: number) =>
  Math.ceil(templatesCount / limit);
