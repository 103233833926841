const compareObject = <T>(
  firstObject: T,
  secondObject: T
): Partial<T> | Boolean => {
  const difference = Object.keys(firstObject).reduce<T>(
    (memo, key: Extract<keyof T, string>) => {
      if (firstObject[key] !== secondObject[key]) {
        memo[key] = firstObject[key];
      }

      return memo;
    },
    {} as T
  );

  return Object.keys(difference).length > 0 && difference;
};

export default compareObject;
