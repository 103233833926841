import { MediaProps, VideoRatioItemProps } from './types';

export const getFormatList = ({ media, videoFormats }: MediaProps) => {
  if (!media) return [];

  return videoFormats
    .filter(({ name }) => media[name])
    .map(item => {
      const { name } = item;
      const mediaItem = media[name];
      return {
        ...item,
        ...mediaItem
      };
    });
};

export const switchFacebookCoverThumbToHorizontal = (
  list: VideoRatioItemProps[]
): VideoRatioItemProps[] => {
  const facebookFormatIndex = list.findIndex(
    format => format.displayName === 'Cover'
  );
  const horizontalFormatIndex = list.findIndex(
    format => format.displayName === 'Horizontal'
  );

  const canSwitchFacebookFormat =
    facebookFormatIndex > 0 && horizontalFormatIndex > 0;

  if (!canSwitchFacebookFormat) return list;

  const listCopy = [...list];

  if (canSwitchFacebookFormat) {
    listCopy[facebookFormatIndex] = {
      ...list[facebookFormatIndex],
      thumb: list[horizontalFormatIndex].thumb
    };
  }

  return listCopy;
};
