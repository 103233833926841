import { VideoFormatType } from '@anm/api/modules/video';
import selectProps from '@anm/helpers/store/selectProps';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

export const selectVideoFormatsCount = ({ video }: ApplicationState) => video.formats.data.length;

export const selectVideoFormats = ({ video }: ApplicationState) => video.formats.data;

export const selectVideoFormatNamesByAspects = (aspects: string[]) => (state: ApplicationState) => {
  const videoFormats = selectVideoFormats(state);

  const selectedVideoFormats = videoFormats.filter(({ name }) => aspects.some(aspect => name.includes(aspect)));

  return selectedVideoFormats.map(({ name }) => name);
};

export const selectFormatsByFormat = createSelector(
  selectVideoFormats,
  selectProps<VideoFormatType | undefined>(),
  (videoFormats, currentFormat) => videoFormats.filter(f => f.format === currentFormat)
);

export const selectVideoFormatByName = createSelector(
  selectVideoFormats,
  selectProps<string>(),
  (videoFormats, videoFormatName) => videoFormats.find(videoFormat => videoFormat.name === videoFormatName)
);
