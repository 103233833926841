import { TemplateItem } from '@anm/api/modules/templates';
import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { fetchTemplatesForSliderAsync, setTemplatesPageName } from '../actions';
import { getCategoryErrorState, getCategoryTemplate } from '../helpers';
import { CategoryTemplates, TemplateActions } from '../types';

import { initialTemplateState } from '.';

const templateListByCategoryReducer: Reducer<
  typeof initialTemplateState,
  TemplateActions
> = (state = initialTemplateState, action) => {
  switch (action.type) {
    case getType(setTemplatesPageName): {
      return {
        ...state,
        pageName: action.payload
      };
    }
    case getType(fetchTemplatesForSliderAsync.request): {
      const key = action.payload.storeKey;

      return {
        ...state,
        templatesItemsForSlider: {
          ...state.templatesItemsForSlider,
          data: {
            ...state.templatesItemsForSlider.data,
            [key]: {
              ...(state.templatesItemsForSlider.data?.[key] || {}),
              isPending: true,
              params: action.payload,
              list: [] as TemplateItem[]
            } as CategoryTemplates
          }
        }
      };
    }
    case getType(fetchTemplatesForSliderAsync.success): {
      const data = state.templatesItemsForSlider.data || {};

      const newData = getCategoryTemplate(data, action.payload);

      return {
        ...state,
        templatesItemsForSlider: {
          ...state.templatesItemsForSlider,
          data: newData
        }
      };
    }
    case getType(fetchTemplatesForSliderAsync.failure): {
      const data = state.templatesItemsForSlider.data || {};

      const newData = getCategoryErrorState(data, action.payload);

      return {
        ...state,
        data: newData
      };
    }
    default:
      return state;
  }
};

export default templateListByCategoryReducer;
