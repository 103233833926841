import api from '@anm/api';
import { call, fork, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';

import asyncEntity from '@anm/helpers/redux/asyncEntity';

import { videoActions } from '.';
import { selectVideoFormats } from './selectors';

const fetchVideoFormatsAsync = asyncEntity(videoActions.getVideoFormatsAsync, () => api().video.getVideoFormats());

function* loadVideoFormats() {
  const formats = yield* select(selectVideoFormats);
  if (formats.length === 0) {
    yield call(fetchVideoFormatsAsync);
  }
}

function* watchVideoFormats() {
  yield take(takeType(videoActions.getVideoFormats));
  yield fork(loadVideoFormats);
}

const videoWatchers = () => [call(watchVideoFormats)];

export default videoWatchers;
