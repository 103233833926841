import api from '@anm/api';
import { SendTemplateLinkToEmailProps, TemplateListParams, UpdateTemplateParams } from '@anm/api/modules/templates';
import isEmpty from '@anm/helpers/is/isEmpty';
import isServer from '@anm/helpers/is/isServer';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { actionChannel, call, delay, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import flatten from 'lodash/fp/flatten';
import isEqual from 'lodash/fp/isEqual';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/isNil';
import { buffers } from 'redux-saga';
import { ActionWithPayload } from 'store';

import combineStringsArr from '../../../helpers/combineStringsArr';
import compareObject from '../../../helpers/compareObjects';
import { filterActions, filterSelectors, TemplatesFilterData } from '../filter';
import { selectVideoFormats, selectVideoFormatNamesByAspects, videoActions } from '../video';

import { templateActions } from '.';
import {
  addGeneratedTemplates,
  fetchCurrentTemplateAsync,
  fetchTemplateListByFilter,
  fetchTemplateListByPage,
  fetchTemplateView,
  generateTemplates,
  sendTemplateLinkToEmail,
  updateTemplate
} from './actions';
import { generateAllFormatTemplates, getOffset } from './helpers';
import {
  getTemplateItemsState,
  selectGeneratedList,
  selectTemplatesByCategory,
  selectTemplatesCurrentPage,
  selectTemplateBySlugOrId,
  selectTemplateList,
  selectTemplateListFetchParams
} from './selectors';
import { FetchTemplateListByPageParams, TemplateListFilters, TemplateListState, TemplateSliderParams } from './types';

function* resetTemplateItemsByFilters(newFilters: TemplateListFilters = {}) {
  const { filters: prevFilters = {} }: TemplateListState = yield select(getTemplateItemsState);

  const isFiltersDifferent = compareObject(newFilters, prevFilters);

  if (isFiltersDifferent) {
    yield put(templateActions.resetTemplateList());
  }
}

function* waitForVideoFormats() {
  yield* put(videoActions.getVideoFormats());
  yield* call(delay, 1);

  return yield* call(function*() {
    yield take(videoActions.getVideoFormatsAsync.success);
  });
}

function* getCurrentVideoAspects(formatsStr?: string, platformsStr?: string) {
  yield* call(waitForVideoFormats);

  const [formats, platforms] = [formatsStr, platformsStr].map(val => val && val.split(','));

  const combinePlatformAndFormats = () =>
    formats && platforms && combineStringsArr(formats, platforms, (format, platform) => `${platform}_${format}`);

  const currentPlatformAspect =
    formats && platforms
      ? combinePlatformAndFormats()
      : formats && !platforms
      ? yield* select(selectVideoFormatNamesByAspects(formats))
      : yield* select(selectVideoFormatNamesByAspects(platforms as string[]));

  return currentPlatformAspect;
}

function* getTemplateListParams(newFilters?: TemplatesFilterData) {
  const {
    aspects: aspectsStr,
    formats: formatsStr,
    platforms: platformsStr,
    category = '',
    name = '',
    ...other
  }: TemplatesFilterData = yield newFilters;

  const queryObj = {
    name,
    category,
    aspects: aspectsStr,
    tags: name.split(' ').toString(),
    ...other
  };

  const queryParams: TemplateListParams = omitBy(v => v === null || v === undefined || v === '')(queryObj);

  if (formatsStr || platformsStr) {
    const newAspects = yield* call(getCurrentVideoAspects, formatsStr, platformsStr);

    if (!isEmpty(newAspects) && !!newAspects) {
      const aspects = aspectsStr ? aspectsStr.split(',') : [];
      queryParams['aspects'] = flatten<string>([aspects, newAspects]).join(',');
    }
  }
  return queryParams;
}

const fetchTemplateListAsync = asyncEntity(templateActions.fetchTemplateListAsync, (params: TemplateListParams) =>
  api().templates.getTemplateList(params)
);

function* fetchGeneratedTemplateList() {
  const { category, name, aspects } = yield* select(filterSelectors.selectTemplatesFilters);
  const createdGeneratedList = yield* select(selectGeneratedList);
  const templateList = yield* select(selectTemplateList);
  const videoFormats = yield* select(selectVideoFormats);
  const canGenerateTemplates = (category || name) && !aspects;

  if (!canGenerateTemplates) return;

  const generatedList = createdGeneratedList.count
    ? createdGeneratedList
    : generateAllFormatTemplates(templateList, videoFormats);

  yield* put(addGeneratedTemplates(generatedList));
}

function* watchGenerateTemplates() {
  while (true) {
    yield take(takeType(generateTemplates));

    yield* call(fetchGeneratedTemplateList);
  }
}

function* watchUpdateTemplateList() {
  while (true) {
    yield take([takeType(filterActions.update)]);

    const currentPage = yield* select(selectTemplatesCurrentPage);

    yield call(fetchTemplateListPage, { page: currentPage });
  }
}

const fetchCurrentTemplateRequest = (templateIdOrSlug: string) => {
  const { getTemplateById, getTemplateBySlug } = api().templates;
  const isId = /^[a-z0-9]{24}$/.test(templateIdOrSlug);
  return isId ? getTemplateById : getTemplateBySlug;
};

const fetchCurrentTemplate = asyncEntity(fetchCurrentTemplateAsync, (templateIdOrSlug: string) =>
  fetchCurrentTemplateRequest(templateIdOrSlug)(templateIdOrSlug)
);

function* watchFetchTemplateView() {
  while (true) {
    const { payload } = yield take(takeType(fetchTemplateView));
    const { templateIdOrSlug } = payload;

    const template = yield* select(state => selectTemplateBySlugOrId(state, templateIdOrSlug));

    template
      ? yield* put(fetchCurrentTemplateAsync.success(template))
      : yield* call(fetchCurrentTemplate, templateIdOrSlug);
  }
}

const getDefaultCategory = (category?: string) => (category ? `/${category.replace(/^(\/|%2F)/, '')}` : '');

function* updateTemplatesFilterWithDefault(templateFilter: TemplatesFilterData) {
  const { category, price_min, price_max } = templateFilter;

  const defaultFilter: TemplatesFilterData = {
    category: getDefaultCategory(category),
    price_min: !price_min && !price_max ? '0' : price_min
  };

  const templatesFilter: TemplatesFilterData = {
    ...templateFilter,
    ...defaultFilter
  };

  yield put(
    filterActions.update({
      templates: templatesFilter
    })
  );
}

function* fetchTemplatesByFilter(templateFilter: TemplatesFilterData, requiredParams: {}) {
  isEmpty(templateFilter) && !isServer()
    ? yield* call(fetchTemplateListAsync, requiredParams)
    : yield* call(updateTemplatesFilterWithDefault, templateFilter);
}

function* watchFetchTemplateListByFilter() {
  while (true) {
    const { payload } = yield take(takeType(fetchTemplateListByFilter));
    const { filters } = yield* select(getTemplateItemsState);
    const { limit } = yield* select(selectTemplateListFetchParams);

    const pageNumber = yield* select(selectTemplatesCurrentPage);

    const offset = getOffset(pageNumber, limit);

    const fetchParams = {
      limit,
      offset
    };

    const compactPayload = omitBy(isNil)({
      aspects: filters.aspects,
      ...payload
    });

    const newFilters = {
      category: '',
      price_min: '0',
      ...compactPayload,
      ...(!isServer() && location.search.includes('isSemrush=true') && { price_max: '0' })
    };

    const tagsFromName = filters.name?.split(' ').join(',');

    const oldFilters = {
      ...filters,
      ...(filters.name && { tags: tagsFromName })
    };

    const isFiltersEqual = isEqual(newFilters, oldFilters);

    if (!isFiltersEqual) {
      yield fork(fetchTemplatesByFilter, newFilters, fetchParams);
    } else {
      yield fork(fetchTemplateListPage, { page: pageNumber });
    }
  }
}

const updateTemplateAsync = asyncEntity(templateActions.updateTemplateAsync, (params: UpdateTemplateParams) =>
  api().templates.updateTemplate(params)
);

function* watchUpdateTemplate() {
  while (true) {
    const { payload }: ReturnType<typeof updateTemplate> = yield take(takeType(updateTemplate));

    yield call(updateTemplateAsync, payload);
  }
}

const fetchTemplatesForSliderAsync = asyncEntity(
  templateActions.fetchTemplatesForSliderAsync,
  (params: TemplateListParams) => api().templates.getTemplateList(params)
);

function* fetchTemplatesForSlider(payload: TemplateSliderParams) {
  while (true) {
    const { storeKey, ...restPayload } = payload;

    const template = yield* select(state => selectTemplatesByCategory(state, storeKey));

    const isEqualParams = isEqual(payload, template?.params);
    if (isEqualParams) return;

    yield call(fetchTemplatesForSliderAsync, restPayload, {
      storeKey,
      ...restPayload
    });
  }
}

function* watchFetchTemplateByCategory() {
  const requestChan = yield* actionChannel(
    takeType(templateActions.fetchTemplatesForSlider),
    buffers.expanding<ActionWithPayload<string, TemplateSliderParams>>()
  );

  while (true) {
    const { payload } = yield take(requestChan);

    yield call(fetchTemplatesForSlider, payload);
  }
}

const sendTemplateLinkToEmailRequest = (params: SendTemplateLinkToEmailProps) =>
  api().templates.sendTemplateLinkToEmail(params);

function* watchSendTemplateLinkToEmail() {
  while (true) {
    const { payload }: ReturnType<typeof sendTemplateLinkToEmail> = yield take(takeType(sendTemplateLinkToEmail));

    yield call(sendTemplateLinkToEmailRequest, payload);
  }
}

function* watchFetchTemplateListByPage() {
  while (true) {
    const { payload }: ReturnType<typeof fetchTemplateListByPage> = yield take(takeType(fetchTemplateListByPage));

    yield call(fetchTemplateListPage, payload);
  }
}

function* fetchTemplateListPage({ page, canMerge }: FetchTemplateListByPageParams) {
  const filters = yield* select(filterSelectors.selectTemplatesFilters);

  yield call(resetTemplateItemsByFilters, filters);

  const { limit, isAllLoaded, count } = yield* select(getTemplateItemsState);

  const offset = getOffset(page, limit);

  const canGenerate = page === 1 && isAllLoaded && count < 6;

  if (canGenerate && false) {
    // TODO: test and discuss template generation for pagination case
    return yield* call(fetchGeneratedTemplateList);
  }

  const queryParams = yield* call(getTemplateListParams, filters);

  const funcPayload: TemplateListParams = {
    ...queryParams,
    offset,
    limit
  };

  const requestPayload: TemplateListFilters = {
    ...filters,
    ...queryParams,
    page,
    canMerge
  };

  yield call(fetchTemplateListAsync, funcPayload, requestPayload);
}

const templateWatchers = () => [
  call(watchUpdateTemplate),
  call(watchFetchTemplateView),
  call(watchGenerateTemplates),
  call(watchUpdateTemplateList),
  call(watchFetchTemplateListByPage),
  call(watchFetchTemplateByCategory),
  call(watchSendTemplateLinkToEmail),
  call(watchFetchTemplateListByFilter)
];

export default templateWatchers;
