import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import {
  cleanUpdateTemplate,
  clearCurrentTemplate,
  fetchCurrentTemplateAsync,
  updateTemplateAsync
} from '../actions';
import { TemplateActions } from '../types';

import { initialTemplateState } from '.';

const currentTemplateReducer: Reducer<
  typeof initialTemplateState,
  TemplateActions
> = (state = initialTemplateState, action) => {
  switch (action.type) {
    case getType(fetchCurrentTemplateAsync.request): {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchCurrentTemplateAsync.success): {
      const { payload: newTemplate } = action;

      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          data: newTemplate,
          isPending: false
        }
      };
    }
    case getType(fetchCurrentTemplateAsync.failure): {
      const { payload: newError } = action;

      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          error: newError,
          isError: true,
          isPending: false
        }
      };
    }
    case getType(clearCurrentTemplate): {
      return {
        ...state,
        currentTemplate: {
          ...initialTemplateState.currentTemplate
        }
      };
    }
    case getType(updateTemplateAsync.request): {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          updateTemplateMeta: {
            isError: false,
            isPending: true
          }
        }
      };
    }
    case getType(updateTemplateAsync.success): {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          updateTemplateMeta: {
            isError: false,
            isPending: false
          }
        }
      };
    }
    case getType(updateTemplateAsync.failure): {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          updateTemplateMeta: {
            isError: true,
            isPending: false
          }
        }
      };
    }
    case getType(cleanUpdateTemplate): {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          updateTemplateMeta:
            initialTemplateState.currentTemplate.updateTemplateMeta
        }
      };
    }
    default:
      return state;
  }
};

export default currentTemplateReducer;
