import { DEFAULT_FETCH_LIMIT } from '@anm/data/video-templates/defaults';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { fetchTemplateListAsync, resetTemplateList } from '../actions';
import { getOffset, getPagesCount } from '../helpers';
import { TemplateActions } from '../types';

import { initialTemplateState } from '.';
import { setCurrentPage } from './../actions';

const templateListReducer: Reducer<typeof initialTemplateState, TemplateActions> = (
  state = initialTemplateState,
  action
) => {
  switch (action.type) {
    case getType(resetTemplateList): {
      return {
        ...state,
        templateItems: {
          ...state.templateItems,
          list: [],
          limit: DEFAULT_FETCH_LIMIT,
          offset: 0,
          filters: { category: '' },
          isError: false,
          isAllLoaded: false
        },
        generatedItems: {
          ...initialTemplateState.generatedItems
        }
      };
    }

    case getType(setCurrentPage): {
      return {
        ...state,
        currentPage: action.payload
      };
    }

    case getType(fetchTemplateListAsync.request): {
      const { page, ...otherParams } = action.payload;

      return {
        ...state,
        currentPage: page || state.currentPage,
        templateItems: {
          ...state.templateItems,
          params: otherParams,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchTemplateListAsync.success): {
      const { list: prevList, limit, params } = state.templateItems;

      const { canMerge, ...filters } = params;

      const { count, content: list } = action.payload;

      const newList = canMerge ? [...prevList, ...list] : list;

      const pagesCount = getPagesCount(state.templateItems.count, limit);

      return {
        ...state,
        templateItems: {
          ...state.templateItems,
          count,
          filters,
          isPending: false,
          list: newList,
          offset: getOffset(state.currentPage, limit),
          isAllLoaded: newList.length >= count || state.currentPage === pagesCount
        }
      };
    }
    case getType(fetchTemplateListAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        templateItems: {
          ...state.templateItems,
          isError: true,
          error: newError,
          isPending: false
        }
      };
    }
    default:
      return state;
  }
};

export default templateListReducer;
