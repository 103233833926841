import { ApiError } from '@anm/api';
import { VideoFormat } from '@anm/api/modules/video';
import {
  createAction,
  createAsyncAction
} from '@anm/helpers/saga/typesafe-actions';

export const getVideoFormats = createAction(
  'wave.video/video/GET_VIDEO_FORMATS'
)();

export const getVideoFormatsAsync = createAsyncAction(
  'wave.video/video/GET_VIDEO_FORMATS_REQUEST',
  'wave.video/video/GET_VIDEO_FORMATS_SUCCESS',
  'wave.video/video/GET_VIDEO_FORMATS_ERROR'
)<void, VideoFormat[], ApiError>();
