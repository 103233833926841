import { DEFAULT_FETCH_LIMIT } from '@anm/data/video-templates/defaults';
import { PageName } from '@anm/data/video-templates/pages';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import {
  addGeneratedTemplates,
  cleanUpdateTemplate,
  clearCurrentTemplate,
  fetchCurrentTemplateAsync,
  fetchTemplatesForSliderAsync,
  fetchTemplateListAsync,
  resetTemplateList,
  setCurrentPage,
  setTemplatesPageName,
  updateTemplateAsync
} from '../actions';
import { TemplateActions, TemplateState } from '../types';

import currentTemplateReducer from './currentTemplateReducer';
import generatedTemplateListReducer from './generatedTemplateListReducer';
import templateListReducer from './templateListReducer';
import templateListsForSliderReducer from './templateListsForSliderReducer';

const commonState = {
  isError: false,
  isPending: false
};

export const initialTemplateState: TemplateState = {
  pageName: '' as PageName,
  currentPage: 1,
  templateItems: {
    list: [],
    count: 0,
    limit: DEFAULT_FETCH_LIMIT,
    offset: 0,
    params: {},
    filters: { category: '' },
    isAllLoaded: false,
    ...commonState
  },
  generatedItems: {
    list: [],
    count: 0,
    limit: DEFAULT_FETCH_LIMIT,
    offset: 0,
    ...commonState
  },
  currentTemplate: {
    data: null,
    ...commonState,
    updateTemplateMeta: commonState
  },
  templatesItemsForSlider: {}
};

const templateReducer: Reducer<TemplateState, TemplateActions> = (
  state = initialTemplateState,
  action
) => {
  switch (action.type) {
    case getType(resetTemplateList):
    case getType(setCurrentPage):
    case getType(fetchTemplateListAsync.request):
    case getType(fetchTemplateListAsync.success):
    case getType(fetchTemplateListAsync.failure):
      return templateListReducer(state, action);

    case getType(clearCurrentTemplate):
    case getType(fetchCurrentTemplateAsync.request):
    case getType(fetchCurrentTemplateAsync.success):
    case getType(fetchCurrentTemplateAsync.failure):
    case getType(updateTemplateAsync.request):
    case getType(updateTemplateAsync.success):
    case getType(updateTemplateAsync.failure):
    case getType(cleanUpdateTemplate):
      return currentTemplateReducer(state, action);

    case getType(addGeneratedTemplates):
      return generatedTemplateListReducer(state, action);

    case getType(setTemplatesPageName):
    case getType(fetchTemplatesForSliderAsync.request):
    case getType(fetchTemplatesForSliderAsync.success):
    case getType(fetchTemplatesForSliderAsync.failure):
      return templateListsForSliderReducer(state, action);

    default:
      return state;
  }
};

export default templateReducer;
